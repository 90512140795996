@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.modalPromo {
    .modal-dialog {
        @include media-breakpoint-up(lg) {
            max-width: 800px;
        }
        height: 400px;
        .modal-content {
            position: relative;
            border-radius: 14px;
            background-color: transparent;
            border: 0;
            .btn-close {
                position: absolute;
                right: 24px;
                top: 24px;
                background: rgba($white, 0.1);
                width: 46px;
                height: 46px;
                border-radius: $radius-circle;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                z-index: 2;
                .icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='Group_511' data-name='Group 511' transform='translate(0.07 0.069)'%3E%3Crect id='Rectangle_237' data-name='Rectangle 237' width='20' height='20' transform='translate(-0.07 -0.069)' fill='none'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M-11.07,1.026a.928.928,0,0,1-.658-.273.93.93,0,0,1,0-1.316L-.562-11.727a.93.93,0,0,1,1.316,0,.93.93,0,0,1,0,1.316L-10.412.754A.928.928,0,0,1-11.07,1.026Z' transform='translate(15.417 15.417)' fill='%23fff'/%3E%3Cpath id='Line_2' data-name='Line 2' d='M.1,1.026A.928.928,0,0,1-.562.754L-11.727-10.412a.93.93,0,0,1,0-1.316.93.93,0,0,1,1.316,0L.754-.562a.93.93,0,0,1,0,1.316A.928.928,0,0,1,.1,1.026Z' transform='translate(15.417 15.417)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 20px;
                    width: 20px;
                    height: 20px;
                }
                @include media-breakpoint-down(sm) {
                    right: 0;
                    top: -58px;
                }
            }
            .modal-body {
                padding: 0;
                .item-link-img {
                    display: block;
                    text-decoration: none;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 14px;
                    }
                }
            }
        }
    }
}
