@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tc-main-header {
    margin-bottom: 76px;
    .navbar {
        .navbar-brand {
            margin-left: 30px;
            margin-right: 0;
            img {
                width: 126px;
                height: 48px;
                object-fit: contain;
            }
            .light-mode {
                display: none;
            }
        }
        .tc-grup-mobile {
            display: flex;
            align-items: center;
            column-gap: 24px;
            .menu_group {
                display: flex;
                align-items: center;
                column-gap: 12px;
                .text_menu {
                    font-size: $size-14;
                    color: var(--gray-200);
                }
            }
        }
        .navbar-toggler {
            background-color: rgba($white, 0.06);
            width: 46px;
            height: 46px;
            border-radius: $radius-circle;
            padding: 0;
            .navbar-toggler-icon {
                @include dots-six-vertical;
                background-size: 24px;
                height: 24px;
                width: 24px;
            }
        }
        .navbar-nav {
            position: relative;
            display: flex;
            align-items: center;
            padding: 20px;
            column-gap: 30px;
            &.showSubmenu {
                .nav-item {
                    &:not(.dropdown) {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
                .dropdown {
                    &::before {
                        transform: translate(0);
                        opacity: 0.4;
                    }
                }
            }
            .nav-item {
                transition: 0.4s cubic-bezier(0.86, 0, 0.07, 1);
                .dropdown-item {
                    &::before {
                        display: none !important;
                    }
                }
            }
            .nav-link {
                font-size: $size-14;
                font-weight: $medium;
                color: var(--gray-300);
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                @include transition3;
                &:hover {
                    color: var(--white);
                }
                &::before {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 12px;
                    background-color: var(--white);
                    top: -15px;
                    border-radius: $radius-20;
                    transition: 0.85s cubic-bezier(0.86, 0, 0.07, 1);
                    opacity: 0;
                    // transform: translateY(-20px);
                }
                &.active {
                    color: var(--white);
                    &::before {
                        opacity: 1;
                        // transform: translateY(0);
                    }
                }
                &[aria-current="page"] {
                    color: var(--white);
                    &::before {
                        opacity: 1;
                        // transform: translateY(0);
                    }
                }
            }
            .dropdown {
                position: relative;
                &::before {
                    content: "";
                    @include caret-double-left;
                    background-size: 14px;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    right: 96px;
                    top: 12px;
                    opacity: 0;
                    transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
                    transform: translate(10px);
                }
                .dropdown-menu {
                    background-color: transparent;
                    border: 0;
                    display: flex;
                    column-gap: 36px;
                    top: -7px;
                    right: 110px;
                    margin: 0 !important;
                    left: inherit;
                    opacity: 0;
                    pointer-events: none;
                    transition: 0.6s all cubic-bezier(0.65, 0.05, 0.36, 1);
                    background-color: transparent !important;
                    .nav-link {
                        background-color: transparent !important;
                    }
                    &.show {
                        opacity: 1;
                        pointer-events: all;
                        right: 130px;
                    }
                }
            }
            &::before {
                content: "/";
                position: absolute;
                font-size: $size-18;
                font-weight: $medium;
                color: var(--white);
                opacity: 0.14;
                right: -10px;
            }
        }
        .display-left-side {
            display: flex;
            align-items: center;
            flex-direction: row;
            column-gap: 22px;
            .item-link-contact {
                display: inline-flex;
                align-items: center;
                column-gap: 10px;
                span {
                    font-size: $size-13;
                    font-weight: $semiBold;
                    color: var(--white);
                }
                .icon_chat {
                    background-size: 22px;
                    width: 22px;
                    height: 22px;
                    @include chats-circle;
                }
            }
            .item-link-ouline {
                font-size: $size-13;
                font-weight: $semiBold;
                color: var(--white);
                box-shadow: inset 0 0 1px 1px var(--white) !important;
                border-radius: $radius-rounded;
                @include transition3;
                &:hover {
                    background-color: rgba($white, 0.06);
                }
            }
        }
        .theme-switch {
            &:checked {
                + {
                    .lightMode {
                        .sun {
                            opacity: 0;
                            transform: translateY(16px);
                        }
                        .moon {
                            transform: translateY(0);
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }
            }
        }
        .lightMode {
            position: relative;
            overflow: hidden;
            @include iconBox;
            box-shadow: inset 0 0 0.8px 0.8px rgba($white, 0.08) !important;
            @include transition3;
            .icon_svg {
                width: 22px;
                height: 22px;
                background-size: 22px;
                position: absolute;
                transition: 0.6s cubic-bezier(0.79, 0.14, 0.15, 0.86);
                &.sun {
                    @include sun;
                }
                &.moon {
                    @include moon-stars;
                    opacity: 0;
                    pointer-events: none;
                    transform: translateY(-16px);
                }
            }
        }
    }
    &.home_header {
        margin-top: 64px;
        position: absolute;
        width: 100%;
        z-index: 50;
        @media (min-width: 768px) and (max-width: 1100px) {
            margin-top: 28px;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 10px;
        }
    }
}

header {
    &.simple_head {
        padding: 20px 0;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
            padding: 10px 0;
        }
        .navbar {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            .display-left-side {
                display: flex;
            }
            .navbar-nav {
                &::before {
                    display: none;
                }
            }
        }
    }
}

// NAV& TABS
.nav-tabs {
    border: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    .nav-item {
        .nav-link {
            font-size: $size-15;
            font-weight: $medium;
            color: var(--white);
            border: 0;
            border-radius: $radius-rounded;
            box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;
            @include transition3;
            @include media-breakpoint-down(sm) {
                font-size: $size-13;
                padding: 12px 18px;
            }
            &.active {
                background-color: var(--white);
                color: var(--dark);
            }
        }
    }
}

.nav-tabs-text {
    padding: 0;
    margin-bottom: 20px;
    .nav-item {
        .nav-link {
            border: 0;
            border-radius: $radius-rounded;
            background-color: transparent;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            span {
                font-size: $size-15;
                font-weight: $medium;
                color: $white;
                opacity: .5;
                @include transition3;
            }
            i {
                opacity: 0.5;
                @include transition3;
            }
            @include media-breakpoint-down(sm) {
                font-size: $size-13;
                padding: 12px 18px;
            }
            .icon-whats {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpath d='M128,32A96.1,96.1,0,0,0,45.4,177h0L36,210.2a7.9,7.9,0,0,0,9.8,9.8L79,210.6h0A96,96,0,1,0,128,32Zm24.1,152A79.9,79.9,0,0,1,72,103.9,28,28,0,0,1,100,76h0a6.8,6.8,0,0,1,6,3.5l11.7,20.4a8.1,8.1,0,0,1-.1,8.1l-9.4,15.7h0a48,48,0,0,0,24.1,24.1h0l15.7-9.4a8.1,8.1,0,0,1,8.1-.1L176.5,150a6.8,6.8,0,0,1,3.5,6A28.1,28.1,0,0,1,152.1,184Z' opacity='0.2'%3E%3C/path%3E%3Cpath d='M45.4,177A95.9,95.9,0,1,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177Z' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3Cpath d='M152.1,184A79.9,79.9,0,0,1,72,103.9,28,28,0,0,1,100,76h0a6.8,6.8,0,0,1,6,3.5l11.7,20.4a8.1,8.1,0,0,1-.1,8.1l-9.4,15.7h0a48,48,0,0,0,24.1,24.1h0l15.7-9.4a8.1,8.1,0,0,1,8.1-.1L176.5,150a6.8,6.8,0,0,1,3.5,6h0A28.1,28.1,0,0,1,152.1,184Z' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 20px;
                width: 20px;
                height: 20px;
            }
            .icon-mail {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolygon points='224 96 145.5 152 110.5 152 32 96 128 32 224 96' opacity='0.2'%3E%3C/polygon%3E%3Cpath d='M32,96V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V96L128,32Z' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3Cline x1='110.5' y1='152' x2='34.5' y2='205.7' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cline x1='221.5' y1='205.7' x2='145.5' y2='152' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cpolyline points='224 96 145.5 152 110.5 152 32 96' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/polyline%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 20px;
                width: 20px;
                height: 20px;
            }
            &.active {
                span {
                    opacity: 1;
                }
                i {
                    opacity: 1;
                }
            }
        }
    }
}
