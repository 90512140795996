@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


.tc-works {
    @include media-breakpoint-up(lg) {
        margin-bottom: 100px;
    }
}
//WORK SECTION
.tch-block-layout {
    padding-top: var(--space-section);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16%;
    @include media-breakpoint-down(lg) {
        grid-gap: 10%;
    }
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-gap: 50px;
    }
    .right-items {
        margin-top: 110px;
        @include media-breakpoint-down(md) {
            margin-top: 0;
            order: 1;
        }
        div {
            .tc-item-work {
                margin-bottom: 50px;
            }
            &:last-child {
                .tc-item-work {
                    margin-bottom: 0;
                }
            }
        }
    }
    .left-items {
        div {
            .tc-item-work {
                margin-bottom: 50px;
            }
            &:last-child {
                .tc-item-work {
                    margin-bottom: 0;
                }
            }
        }
    }
    .tch-start-project {
        position: relative;
        @include gradient-opacity;
        border-radius: $radius-14;
        padding: 70px 50px;
        @include media-breakpoint-down(md) {
            padding: 50px;
        }
        .btn-gradient {
            margin-top: 30px;
            span {
                font-weight: $medium;
            }
        }
        #work_anime {
            width: 240px;
            height: 240px;
            position: absolute;
            left: 50px;
            top: 70%;
            overflow: hidden;
            @media (min-width: 1200px) and (max-width: 1286px) {
                left: 10px;
            }
            @include media-breakpoint-down(lg) {
                display: none;
            }
            svg {
                path {
                    fill: var(--white);
                }
            }
        }
    }
}

// WORK ITEM
.tc-item-work {
    display: block;
    text-decoration: none;
    .work_img {
        height: 334px; //574px
        width: 100%;
        border-radius: $radius-14;
        object-fit: cover;
        @include media-breakpoint-down(md) {
            height: 314px;
        }
    }
    .display-title {
        margin-top: 24px;
        h3 {
            font-size: $size-24;
            font-weight: $medium;
            color: var(--white);
            margin: 0;
        }
        p {
            font-size: $size-15;
            font-weight: $normal;
            margin: 0;
            margin-top: 10px;
            color: var(--gray-200);
        }
    }
}

.bg-primary {
    background-color: var(--primary) !important;
}

// TAB
.tts-tabs-works {
    margin-top: 50px;
    margin-bottom: var(--space-section-inner);
    .nav-tabs {
        display: flex;
        justify-content: center;
        .nav-item {
            .nav-link {
                box-shadow: none !important;
                color: var(--gray-400);
                transition: 0.6s cubic-bezier(0.45, 0.05, 0.55, 0.95);
                &.active {
                    background-color: rgba($white, 0.06);
                    color: var(--white);
                }
            }
        }
    }
}

// HEAD WORKS - INNER
.tts-works-inner {
    .tts-type-develop {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 60px;
        row-gap: 20px;
        .display-grid-text {
            span {
                font-size: $size-18;
                color: var(--gray-400);
                margin-bottom: 6px;
                display: block;
            }
            p {
                font-size: $size-20;
                font-weight: $medium;
                color: var(--white);
                margin-bottom: 0;
            }
        }
    }
}

.tts-we-do {
    &.space-case-inner {
        padding-top: calc($space-section-inner - 10px);
        @include media-breakpoint-down(sm) {
            padding-top: calc($space-section-inner - 40px);
        }
        .btn {
            &.space-top {
                margin-top: 40px;
            }
        }
    }
    &.space-section {
        padding-top: var(--space-section);
    }
}

//
.tts-wrapper-caseWork-inner {
    padding-top: var(--space-section);
    .tch-block-layout {
        @include media-breakpoint-down(md) {
            grid-gap: 0;
        }
        .right-items {
            .tc-item-work {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .tc-item-work {
            margin-bottom: 50px;
            .work_img {
                @include media-breakpoint-up(md) {
                    height: 100%;
                    max-height: 574px;
                }
            }
            .display-title {
                .info_img {
                    font-size: $size-24;
                    color: var(--white);
                    text-align: center;
                    @include media-breakpoint-up(lg) {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

.hero_cover_service {
    &.space-section {
        padding-top: var(--space-section);
        padding-bottom: 0;
    }
}


.tts-next-projext-section {
    text-align: center;
    padding: 150px 0 20px 0;
    @include media-breakpoint-down(md) {
        padding: 80px 0 0 0;
    }
    .btn-next-project {
        padding: 50px;
        text-decoration: none;
        display: block;
        @include media-breakpoint-down(md) {
            padding: 20px;
        }
        span {
            font-size: $size-24;
            font-weight: $medium;
            color: var(--gray-400);
            margin-bottom: 16px;
            display: block;
        }
        h4 {
            font-size: var(--size-60);
            font-weight: $medium;
            color: var(--white);
            margin: 0;
        }
    }
}