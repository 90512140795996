@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tc-products {
    padding-top: var(--space-section);
    @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
    }
    .tc-head-products {
        position: relative;
        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }
        .display-sections-title {
            @include media-breakpoint-down(md) {
                margin-bottom: 40px;
            }
            h2 {
                .gradient-text {
                    @include gradient-text;
                }
            }
        }
        .shape_arrow {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='103.461' height='22.471' viewBox='0 0 103.461 22.471'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_144' data-name='Rectangle 144' width='103.461' height='22.471' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_243' data-name='Group 243' transform='translate(0 0)'%3E%3Cg id='Group_242' data-name='Group 242' transform='translate(0 0)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1218' data-name='Path 1218' d='M95.433,2.21a24.069,24.069,0,0,0,2.818,2.726c.194.169.946.684,1.592,1.155-13.239.044-26.478-.234-39.716-.261-1.165,0-2.373.015-3.583.094C51.454-.29,40.822-1.365,33.784,1.531c-6,2.468-10.588,7.309-16.459,10-3.186,1.459-6.806,2.3-10.241,1.245C3.7,11.735.881,9,.623,5.356c-.028-.4-.637-.4-.622,0a9.18,9.18,0,0,0,5.062,7.632c3.543,1.955,7.7,1.7,11.408.332,4.515-1.667,8.273-4.705,12.259-7.309C33.344,3,38.331,1.043,43.935,1.634a17.594,17.594,0,0,1,10.3,4.533c-3.078.461-6,1.536-8.08,4.019-2.343,2.8-3.238,7.2-.5,10.064a7.983,7.983,0,0,0,10.256.672c3.135-2.408,4.06-6.887,2.978-10.564A12.649,12.649,0,0,0,57.8,7.746c1.345-.032,2.675,0,3.918.016,12.682.157,25.372.231,38.052.536-.554.549-1.146,1.086-1.3,1.258l-2.976,3.266c-1.209,1.327.8,3.316,1.967,1.967l4.06-4.679c.666-.768,1.972-1.848,1.946-3.02-.028-1.282-1.161-1.98-2.083-2.634a31.711,31.711,0,0,0-5.2-3.219.621.621,0,0,0-.75.971M52.313,20.429a5.727,5.727,0,0,1-5.519-1.86c-1.577-2.051-.921-4.819.5-6.758,2-2.726,5.128-3.652,8.381-3.948a11.038,11.038,0,0,1,.9,1.514c1.913,3.951.691,10.172-4.259,11.052' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 104px;
            width: 104px;
            height: 23px;
            position: absolute;
            left: 37%;
            top: 50%;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }
    .tc-products-swiper {
        padding-top: 60px;
        @include media-breakpoint-down(md){
            padding-top: 40px;
        }
        .splide__track {
            border-radius: 14px;
        }
        .splide__arrows {
            display: flex;
            column-gap: 14px;
            position: absolute;
            top: -50px;
            right: 90px;
            @include media-breakpoint-down(lg) {
                right: inherit;
                left: 0;
                top: 20px;
            }
            @include media-breakpoint-down(md) {
                top: -29px;
            }
            .splide__arrow {
                position: relative;
                inset: 0;
                @include iconBox;
                background-color: transparent;
                box-shadow: 0 0 1px 1px rgba($white, 1) !important;
                @include transition3;
                opacity: 1 !important;
                &:disabled {
                    box-shadow: 0 0 1px 1px rgba($white, 0.12) !important;
                }
                .icon {
                    background-size: 18px;
                    width: 18px;
                    height: 18px;
                }
                &.splide__arrow--prev {
                    .icon {
                        @include arrow-left;
                    }
                }
                &.splide__arrow--next {
                    .icon {
                        @include arrow-right;
                    }
                }
            }
        }
        .splide__pagination {
            bottom: -50px;
            .splide__pagination__page {
                background-color: var(--gray-300);
            }
        }
    }
}

// PRODUCT ITEM
.tc-wrapper-products {
    height: 386px;
    position: relative;
    overflow: hidden;
    padding: 30px;
    background-color: rgba($white, 0.06);
    border-radius: $radius-14;
    display: block;
    text-decoration: none;
    .tc-number-hexa {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        margin-bottom: 40px;
        &::before {
            content: "";
            @include boxHexa;
            background-size: 64px;
            width: 100%;
            height: 100%;
            opacity: 0.12;
            position: absolute;
        }
        span {
            font-size: $size-14;
            font-weight: $medium;
            color: var(--white);
        }
    }
    .tc-display-text {
        h3 {
            font-size: $size-16;
            font-weight: $bold;
            margin: 0;
            margin-bottom: 10px;
            color: var(--white);
        }
        p {
            font-size: $size-16;
            font-weight: $normal;
            color: var(--white);
            margin-bottom: 0;
            display: -webkit-box;
            height: 96px;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .tc-brand-other {
        position: absolute;
        bottom: 30px;
        img {
            width: 150px;
            height: 50px;
            object-fit: contain;
        }
        .light-mode {
            display: none;
        }
    }
    .icon_arrow {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cline x1='192' y1='192' x2='64' y2='64' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cpolyline points='168 64 64 64 64 168' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/polyline%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 30px;
        left: 24px;
        opacity: 0;
        transform: translate3d(20px, 10px, 0px);
        transition: 0.35s cubic-bezier(0.77, 0, 0.18, 1);
    }
    .item_cursor {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 50px;
        left: 50px;
    }
    &:hover {
        .icon_arrow {
            transform: translate3d(0, 0, 0px);
            opacity: 1;
        }
    }
}


// INNER PAGE - PRODUCTS
.tts-inner-products {
    margin-top: var(--space-section-inner);
    .item {
        margin-bottom: 30px;
    }
}