@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

// SIDEMENU
.tc-side_menu {
    .modal-dialog {
        transform: translate(0) !important;
        &.modal-fullscreen {
            @include media-breakpoint-down(md) {
                height: auto;
            }
        }
    }
    .modal-content {
        background-color: rgba($dark, 0.85);
        backdrop-filter: saturate(6) blur(20px);
        @-moz-document url-prefix() {
            background-color: rgba($dark, 0.99);
        }
        padding: 40px;
        @include media-breakpoint-down(sm) {
            padding: 20px;
        }
        .modal-header {
            padding: 0;
            border: 0;
            margin-bottom: 50px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
            .tc_menu_head {
                display: flex;
                align-items: center;
                flex-direction: row;
                column-gap: 22px;
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }
                .item-link-contact {
                    display: inline-flex;
                    align-items: center;
                    column-gap: 10px;
                    span {
                        font-size: $size-13;
                        font-weight: $semiBold;
                        color: var(--white);
                    }
                    .icon_chat {
                        background-size: 22px;
                        width: 22px;
                        height: 22px;
                        @include chats-circle;
                    }
                }
                .item-link-ouline {
                    font-size: $size-13;
                    font-weight: $semiBold;
                    color: var(--white);
                    box-shadow: inset 0 0 1px 1px var(--white) !important;
                    border-radius: $radius-rounded;
                    @include transition3;
                    &:hover {
                        background-color: rgba($white, 0.06);
                    }
                }
            }
            .btn-close {
                position: absolute;
                left: 10%;
                background: rgba($white, 0.06);
                width: 46px;
                height: 46px;
                border-radius: $radius-circle;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                .icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='Group_511' data-name='Group 511' transform='translate(0.07 0.069)'%3E%3Crect id='Rectangle_237' data-name='Rectangle 237' width='20' height='20' transform='translate(-0.07 -0.069)' fill='none'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M-11.07,1.026a.928.928,0,0,1-.658-.273.93.93,0,0,1,0-1.316L-.562-11.727a.93.93,0,0,1,1.316,0,.93.93,0,0,1,0,1.316L-10.412.754A.928.928,0,0,1-11.07,1.026Z' transform='translate(15.417 15.417)' fill='%23fff'/%3E%3Cpath id='Line_2' data-name='Line 2' d='M.1,1.026A.928.928,0,0,1-.562.754L-11.727-10.412a.93.93,0,0,1,0-1.316.93.93,0,0,1,1.316,0L.754-.562a.93.93,0,0,1,0,1.316A.928.928,0,0,1,.1,1.026Z' transform='translate(15.417 15.417)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 20px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .modal-body {
            border: 0;
            .nav_link {
                padding: 0;
                .nav-item {
                    padding: 0 30px 0 0;
                    .nav-link {
                        position: relative;
                        padding: 16px 20px;
                        .text {
                            font-size: $size-24;
                            color: var(--white);
                            font-weight: $medium;
                            transition: 0.35s all cubic-bezier(0.77, 0, 0.18, 1);
                        }
                        .number {
                            font-size: $size-12;
                            font-weight: $medium;
                            color: var(--gray-400);
                            position: absolute;
                            top: 4px;
                            right: -22px;
                            transition: 0.5s all cubic-bezier(0.77, 0, 0.18, 1);
                            &::after {
                                content: "";
                                width: 2px;
                                height: 12px;
                                background-color: var(--white);
                                position: absolute;
                                display: block;
                                opacity: 0.14;
                                top: 10px;
                                right: 26px;
                                transform: skew(20deg, 20deg);
                            }
                        }
                        &.sub_menu {
                            display: flex;
                            align-items: center;
                            .text {
                                color: var(--gray-400);
                            }
                            &::before {
                                content: "";
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cg id='Group_555' data-name='Group 555' transform='translate(0.174 0.174)'%3E%3Crect id='Rectangle_236' data-name='Rectangle 236' width='14' height='14' transform='translate(-0.174 -0.174)' fill='none'/%3E%3Cpath id='Path_1292' data-name='Path 1292' d='M112.861,45.722a.632.632,0,0,1-.448-.186l-4.227-4.227a.634.634,0,0,1,0-.9l4.227-4.227a.634.634,0,1,1,.9.9l-3.778,3.778,3.778,3.778a.634.634,0,0,1-.448,1.082Z' transform='translate(-102.231 -34.035)' fill='%23a2a3a7'/%3E%3Cpath id='Path_1293' data-name='Path 1293' d='M32.861,45.722a.632.632,0,0,1-.448-.186l-4.227-4.227a.634.634,0,0,1,0-.9l4.227-4.227a.634.634,0,0,1,.9.9l-3.778,3.778,3.778,3.778a.634.634,0,0,1-.448,1.082Z' transform='translate(-26.457 -34.035)' fill='%23a2a3a7'/%3E%3C/g%3E%3C/svg%3E%0A");
                                background-repeat: no-repeat;
                                background-size: 14px;
                                width: 14px;
                                height: 14px;
                                position: absolute;
                                opacity: 0;
                                transition: 0.35s all cubic-bezier(0.77, 0, 0.18, 1);
                                right: -10px;
                            }
                        }
                        &:hover {
                            .text {
                                padding-right: 18px;
                                color: var(--gray-400);
                            }
                            .number {
                                transform: translate(-14px);
                                opacity: 0;
                            }
                            &.sub_menu {
                                .text {
                                    padding-right: 28px;
                                    color: var(--white);
                                }
                                &::before {
                                    opacity: 1;
                                    transform: translate(-30px);
                                }
                            }
                        }
                        &[aria-current="page"] {
                            .text {
                                padding-right: 18px;
                                color: var(--gray-400);
                            }
                            .number {
                                transform: translate(-14px);
                                opacity: 0;
                            }
                            &.sub_menu {
                                .text {
                                    padding-right: 28px;
                                    color: var(--white);
                                }
                                &::before {
                                    opacity: 1;
                                    transform: translate(-30px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .modal-footer {
            justify-content: start;
            border: 0;
            .tc-wrapper-address {
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
                .address {
                    font-size: $size-16;
                    font-weight: $normal;
                    color: var(--gray-200);
                    margin-bottom: 14px;
                    @include media-breakpoint-up(lg) {
                        width: 70%;
                    }
                }
                .phone {
                    text-decoration: none;
                    color: var(--white);
                    font-weight: $medium;
                    font-size: $size-16;
                }
            }
            .privacy_copyright {
                margin-top: 14px;
                a {
                    color: var(--white);
                    font-size: $size-14;
                    display: inline-block;
                    text-decoration: none;
                    span {
                        @include linear;
                    }
                }
                .dividar {
                    font-size: $size-14;
                    font-weight: $medium;
                    display: inline-block;
                    margin: 0 20px;
                    opacity: 0.3;
                }
                .copyright {
                    color: var(--gray-400);
                    font-size: $size-14;
                }
            }
            .nav_links {
                column-gap: 24px;
                padding: 0;
                .nav-item {
                    margin-bottom: 0;
                    .nav-link {
                        font-size: $size-18;
                        color: var(--white);
                        font-weight: $medium;
                        @include data-hover;
                    }
                }
            }
        }
    }
}

.offcanvas {
    transition: transform 0.8s cubic-bezier(0.77, 0, 0.18, 1);
    background-color: $white;
    &.offcanvas-lib {
        width: 370px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        .offcanvas-header {
            position: absolute;
            width: 100%;
            padding: 28px;
            justify-content: flex-end;
            gap: 14px;
            .btn-close {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_511' data-name='Group 511' transform='translate(0.28 0.279)'%3E%3Crect id='Rectangle_237' data-name='Rectangle 237' width='18' height='18' transform='translate(-0.28 -0.279)' fill='none'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M-11.182-.55a.815.815,0,0,1-.578-.24.818.818,0,0,1,0-1.157l9.814-9.814a.818.818,0,0,1,1.157,0,.818.818,0,0,1,0,1.157L-10.6-.789A.815.815,0,0,1-11.182-.55Z' transform='translate(14.995 14.995)' fill='%2345474f'/%3E%3Cpath id='Line_2' data-name='Line 2' d='M-1.368-.55a.815.815,0,0,1-.578-.24L-11.76-10.6a.818.818,0,0,1,0-1.157.818.818,0,0,1,1.157,0L-.789-1.946a.818.818,0,0,1,0,1.157A.815.815,0,0,1-1.368-.55Z' transform='translate(14.995 14.995)' fill='%2345474f'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 18px;
                width: 46px;
                height: 46px;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                &::before {
                    content: "";
                    @include iconBox;
                    box-shadow: 0 0 1px 1px $gray-100;
                    @include transition2;
                }
                &:hover {
                    &::before {
                        box-shadow: 0 0 1px 1px $gray-200;
                    }
                }
            }
            .external-link {
                width: 46px;
                height: 46px;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 0 1px 1px $gray-100 !important;
                border-radius: $radius-circle;
                @include transition2;
                .icon {
                    background-image: url("data:image/svg+xml,%3Csvg id='Group_7294' data-name='Group 7294' xmlns='http://www.w3.org/2000/svg' width='256' height='256' viewBox='0 0 256 256'%3E%3Cpath id='Path_2330' data-name='Path 2330' d='M0,0H256V256H0Z' fill='none'/%3E%3Cpath id='Path_2327' data-name='Path 2327' d='M216,108a8,8,0,0,1-8-8V48H156a8,8,0,0,1,0-16h60a8,8,0,0,1,8,8v60A8,8,0,0,1,216,108Z' fill='%23171923'/%3E%3Cpath id='Path_2329' data-name='Path 2329' d='M0,80a7.975,7.975,0,0,1-5.657-2.343,8,8,0,0,1,0-11.314l72-72a8,8,0,0,1,11.314,0,8,8,0,0,1,0,11.314l-72,72A7.975,7.975,0,0,1,0,80Z' transform='translate(144 40)' fill='%23171923'/%3E%3Cpath id='Path_2328' data-name='Path 2328' d='M176,224H48a16.018,16.018,0,0,1-16-16V80A16.018,16.018,0,0,1,48,64h64a8,8,0,0,1,0,16H48V208H176V144a8,8,0,0,1,16,0v64A16.018,16.018,0,0,1,176,224Z' fill='%23171923'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 18px;
                    width: 18px;
                    height: 18px;
                }
                &:hover {
                    box-shadow: 0 0 1px 1px $gray-200 !important;
                }
            }
        }
        .offcanvas-body {
            padding: 34px 34px 0 0;
            overflow: hidden;
            .wrapper-file-offcanvas {
                .cover {
                    width: 121px;
                    height: 170px;
                    border-radius: $radius-14;
                    border: 2px solid rgba($dark, 0.06);
                    padding: 10px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: $radius-14;
                    }
                }
                .display-name {
                    margin-top: 22px;
                    padding-left: 34px;
                    margin-bottom: 20px;
                    h3 {
                        font-size: $size-22;
                        font-weight: $medium;
                        margin: 0;
                        margin-bottom: 10px;
                        color: $dark;
                        line-height: 31px;
                    }
                    time {
                        font-size: $size-16;
                        color: $gray-700;
                        margin: 0;
                    }
                }
                .description {
                    overflow-y: auto;
                    height: calc(100vh - 60vh);
                    padding-left: 34px;
                    p {
                        font-size: $size-16;
                        color: $gray-800;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .offcanvas-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 34px;
            .offcanvas__arrows {
                display: flex;
                align-items: center;
                gap: 14px;
                .btn {
                    @include iconBox;
                    box-shadow: 0 0 1px 1px rgba($dark, 0.12) !important;
                    background-color: transparent;
                    border-radius: $radius-circle;
                }
                .btn_prev {
                    .icon {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23171923' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cline x1='40' y1='128' x2='216' y2='128' fill='none' stroke='%23171923' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cpolyline points='144 56 216 128 144 200' fill='none' stroke='%23171923' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/polyline%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: 18px;
                        width: 18px;
                        height: 18px;
                    }
                }
                .btn_next {
                    .icon {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23171923' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cline x1='216' y1='128' x2='40' y2='128' fill='none' stroke='%23171923' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cpolyline points='112 56 40 128 112 200' fill='none' stroke='%23171923' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/polyline%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: 18px;
                        width: 18px;
                        height: 18px;
                    }
                    box-shadow: 0 0 1px 1px rgba($dark, 1) !important;
                }
            }
        }
    }
    .scrollbar-track {
        background-color: transparent;
        width: 5px;
        .scrollbar-thumb {
            width: 5px;
            background-color: $gray-200;
        }
    }
    .scrollbar-track-y {
        left: 20px;
        right: inherit;
    }
}
.offcanvas-backdrop {
    background-color: $dark;
    &.show {
        opacity: 0.8;
    }
}

// ADS
.btn_showAds {
    position: fixed;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    left: 0;
    top: 50%;
    z-index: 10;
    padding: 0;
    animation: slide-in-close-ads 0.5s cubic-bezier(0.77, 0, 0.18, 1) .5s both;
    .icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpath d='M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32ZM112,160V96l48,32Z' opacity='0.2'%3E%3C/path%3E%3Ccircle cx='128' cy='128' r='96' fill='none' stroke='%23ffffff' stroke-miterlimit='10' stroke-width='16'%3E%3C/circle%3E%3Cpolygon points='160 128 112 96 112 160 160 128' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/polygon%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 22px;
        width: 22px;
        height: 22px;
    }
    &.hide {
        animation: slide-out-close-ads 0.5s cubic-bezier(0.77, 0, 0.18, 1) both;
    }
}

.wrapper_ads_place {
    .toast {
        position: fixed;
        z-index: 10;
        left: 36px;
        top: 30%;
        border-radius: $radius-14;
        border: 0;
        background-color: $white;
        animation: slide-out-ads 0.5s cubic-bezier(0.77, 0, 0.18, 1) both;
        box-shadow: 0px 60px 80px -24px rgba($dark, .2);
        @include media-breakpoint-down(sm) {
            left: 16px;
        }
        &:not(.show) {
            display: block;
            opacity: 0;
        }
        &.fade:not(.show) {
            opacity: 1;
        }
        .toast-header {
            background-color: transparent;
            border: 0;
            display: flex;
            justify-content: center;
            padding: 20px;
            .block_title {
                font-size: $size-18;
                font-weight: $medium;
                color: $dark;
                margin: 0;
            }
            .btn-close {
                width: 28px;
                height: 28px;
                position: absolute;
                right: 20px;
                opacity: 1;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23171923' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cline x1='200' y1='56' x2='56' y2='200' fill='none' stroke='%23171923' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3E%3C/line%3E%3Cline x1='200' y1='200' x2='56' y2='56' fill='none' stroke='%23171923' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3E%3C/line%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 13px;
                padding: 0;
                background-color: transparent;
                box-shadow: 0 0 1px 1px $gray-200 !important;
                border-radius: $radius-circle;
                margin: 0;
                @include transition3;
            }
        }
        .toast-body {
            padding: 0 20px 20px 20px;
            .main_img_tts {
                width: 100%;
                height: 100%;
                max-height: 220px;
                object-fit: cover;
                border-radius: $radius-14;
            }
            .item-link-ads {
                display: block;
                text-decoration: none;
            }
            .btn_main {
                border-radius: $radius-14;
                height: 48px;
                width: 100%;
                @include gradient;
                color: $white;
                font-size: $size-15;
                font-weight: $medium;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 12px;
                text-decoration: none;
            }
        }
        &.hide {
            animation: slide-out-ads 0.5s cubic-bezier(0.77, 0, 0.18, 1) both;
        }
        &.show {
            animation: slide-in-ads 0.5s cubic-bezier(0.77, 0, 0.18, 1) .3s both;
        }
        &.showing {
            opacity: 1;
        }
    }
}
