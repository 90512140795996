@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.search-library {
    margin-top: 50px;
}

.tab-filter-lib {
    margin-top: 50px;
    text-align: center;
    .swithcer {
        .nav-item {
            font-size: $size-15;
            font-weight: $medium;
            color: var(--gray-400);
            border-radius: $radius-rounded;
            cursor: pointer;
            @include transition3;
            &.active {
                background-color: rgba($white, 0.06);
                color: var(--white);
            }
        }
    }
    .splideFilter {
        display: flex;
        justify-content: center;
        @include media-breakpoint-down(xl) {
            padding: 0 30px;
        }
    }
    .splide__arrows {
        .splide__arrow {
            width: 24px;
            height: 24px;
            border-radius: $radius-circle;
            background-color: rgba($white, 0.12);
            opacity: 1;
            &.splide__arrow--next {
                opacity: 1;
                .icon {
                    background-repeat: no-repeat;
                    width: 12px;
                    height: 12px;
                    background-size: 12px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='200 208 120 128 200 48' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3E%3C/polyline%3E%3Cpolyline points='120 208 40 128 120 48' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3E%3C/polyline%3E%3C/svg%3E");
                }
            }
            &.splide__arrow--prev {
                opacity: 1;
                .icon {
                    background-repeat: no-repeat;
                    width: 12px;
                    height: 12px;
                    background-size: 12px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='56 48 136 128 56 208' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3E%3C/polyline%3E%3Cpolyline points='136 48 216 128 136 208' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3E%3C/polyline%3E%3C/svg%3E");
                }
            }
            &:disabled {
                opacity: 0;
            }
        }
        @include media-breakpoint-up(xl) {
            opacity: 0;
        }
    }
}

// FILES
.tts-book-inner {
    margin-top: 50px;
    .wrapper-file-book {
        .item {
            &.hide {
                animation: fade-out-filter 0.6s both;
                display: none;
            }
            &.show {
                display: block;
                animation: fade-in-filter 0.6s both;
            }
        }
    }
    .simple-spinner {
        left: -50%;
        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }
    }
}

.item-file {
    margin-bottom: 30px;
    .cover_file {
        position: relative;
        border-radius: $radius-14;
        width: 100%;
        height: 375px;
        padding: 24px;
        border: 2px solid rgba($white, 0.06);
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $radius-14;
            @include transition2;
        }
        .buttons_cover {
            position: absolute;
            top: calc(100% / 2 - 60px);
            display: flex;
            flex-direction: column;
            gap: 20px;
            .btn {
                border-radius: $radius-rounded;
                display: flex;
                min-width: 133px;
                padding: 13px;
                justify-content: center;
                align-items: center;
                opacity: 0;
                pointer-events: none;

                letter-spacing: -1px;
            }
            .btn-outline {
                transition: 0.55s cubic-bezier(0.86, 0, 0.07, 1);
                transform: translateY(20px);
            }
            .btn-download {
                transform: translateY(-20px);
                transition: 0.55s cubic-bezier(0.86, 0, 0.07, 1);
            }
        }
    }
    .display-name {
        margin-top: 24px;
        h3 {
            font-size: $size-18;
            font-weight: $medium;
            margin: 0;
            margin-bottom: 10px;
            color: var(--white);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        time {
            font-size: $size-16;
            color: var(--gray-400);
            margin: 0;
        }
    }
    &:hover {
        .cover_file {
            img {
                opacity: 0.18;
            }
            .buttons_cover {
                .btn {
                    transform: translateY(0);
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }
}

.tab__files {
    .swithcer {
        .nav-item {
            &.active {
                &[data-cat=".all"] {
                    ~ {
                        .item__file {
                            .category {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
.tts-files-inner {
    margin-top: 50px;
    .wrapper-files {
        .item__file {
            padding: 20px 0;
            border-top: 2px solid rgba($white, 0.06);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .display-text {
                a{
                    text-decoration: none;
                    display: block;
                    &:hover {
                        h3 {
                            color: var(--primary);
                        }
                    }
                }
                @include media-breakpoint-down(sm) {
                    width: calc(100% - 70px);
                }
                h3 {
                    font-size: $size-18;
                    font-weight: $medium;
                    color: var(--white);
                    margin: 0 0 10px 0;
                    @include transition2;
                }
                time {
                    font-size: $size-16;
                    color: var(--gray-400);
                    margin: 0;
                }
            }
            .icon_download {
                position: relative;
                overflow: hidden;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                //justify-content: center;
                gap: 6px;
                border-radius: $radius-rounded;
                background-color: var(--primary);
                transition: 0.6s cubic-bezier(0.79, 0.14, 0.15, 0.86);
                padding: 0;
                .icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_608' data-name='Group 608' transform='translate(-75 -2)'%3E%3Cg id='Group_607' data-name='Group 607' transform='translate(75 2)'%3E%3Crect id='Rectangle_253' data-name='Rectangle 253' width='18' height='18' fill='none'/%3E%3Cpath id='Path_1293' data-name='Path 1293' d='M20.334,53.417a5.417,5.417,0,1,1,5.417,5.417H19.792a3.792,3.792,0,1,1,0-7.584,3.894,3.894,0,0,1,.941.115' transform='translate(-14.809 -44.642)' fill='%23fff' opacity='0.2'/%3E%3Cpath id='Path_1294' data-name='Path 1294' d='M112.937,169.479a.54.54,0,0,1-.383-.159l-2.3-2.3a.542.542,0,1,1,.766-.766l1.913,1.913,1.913-1.913a.542.542,0,1,1,.766.766l-2.3,2.3A.54.54,0,0,1,112.937,169.479Z' transform='translate(-102.537 -154.745)' fill='%23fff'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M-7.458-1.5A.542.542,0,0,1-8-2.041V-7.458A.542.542,0,0,1-7.458-8a.542.542,0,0,1,.542.542v5.417A.542.542,0,0,1-7.458-1.5Z' transform='translate(17.859 16.233)' fill='%23fff'/%3E%3Cpath id='Path_1295' data-name='Path 1295' d='M13.959,96.668H12.334a4.334,4.334,0,1,1,0-8.668,4.439,4.439,0,0,1,1.07.131.542.542,0,0,1-.262,1.051,3.37,3.37,0,0,0-.81-.1,3.25,3.25,0,1,0,0,6.5h1.625a.542.542,0,1,1,0,1.083Z' transform='translate(-7.351 -81.934)' fill='%23fff'/%3E%3Cpath id='Path_1296' data-name='Path 1296' d='M82.293,49.711a.542.542,0,0,1-.433-.867,4.876,4.876,0,1,0-8.776-2.925.542.542,0,1,1-1.083,0,5.959,5.959,0,1,1,10.727,3.576A.541.541,0,0,1,82.293,49.711Z' transform='translate(-67.017 -37.144)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 18px;
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    right: 14px;
                    transition: 0.6s cubic-bezier(0.79, 0.14, 0.15, 0.86);
                }
                .text {
                    height: 24px;
                    position: relative;
                    overflow: hidden;
                    right: -10px;
                    width: 100%;
                }
                span {
                    font-size: $size-15;
                    font-weight: $medium;
                    color: $white;
                    position: absolute;
                    display: inline-block;
                    width: max-content;
                    opacity: 0;
                    pointer-events: none;
                    right: 55px;
                    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.8s;
                    transform: skewY(6deg) translateY(-100%);

                    letter-spacing: -1px;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        width: 133px;
                        span {
                            opacity: 1;
                            transform: skewY(0) translateY(0);
                        }
                        .icon {
                            right: 18px;
                        }
                    }
                }
            }
            &.hide {
                animation: fade-out-2 0.6s both;
                display: none;
            }
            &.show {
                display: flex;
                animation: fade-in-2 0.6s both;
            }
            .category {
                display: none;
            }
        }
    }
}

.tts-lib-details-inner {
    .wrapper_sideOne {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
        }
        .main_file_item {
            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;
            }
            .item-file {
                margin-bottom: 24px;
                .cover_file {
                    @include media-breakpoint-down(lg) {
                        width: max-content;
                    }
                    img {
                        opacity: 1 !important;
                    }
                }
            }
            text-align: center;
        }
        .sideLib {
            @include media-breakpoint-down(lg) {
                padding: 0;
                margin: 0 0 20px 0;
                border: 0;
            }
            .goBack {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
    }
    .wrapper-file-text {
        .display-name {
            margin-bottom: 20px;
            h3 {
                font-size: $size-34;
                font-weight: $bold;
                line-height: 51px;
                margin: 0;
                margin-bottom: 10px;
                color: var(--white);
            }
            time {
                font-size: $size-16;
                color: var(--gray-300);
                margin: 0;
            }
        }
        .description {
            p {
                font-size: $size-16;
                color: var(--gray-200);
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .tags {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 12PX;
            span {
                padding: 6px 13px;
                border-radius: 50px;
                font-size: $size-12;
                font-weight: $normal;
                background-color: rgba($white, .1);
                display: inline-block;
            }
        }
    }
}

.sideLib {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    @include media-breakpoint-down(lg) {
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 30px;
        border-top: 2px solid rgba($white, 0.06);
        padding-top: 20px;
    }
    .social-networks {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            column-gap: 14px;
            flex-direction: row;
        }
        p {
            font-size: $size-16;
            font-weight: $medium;
            margin-bottom: 0;
        }
        .nav {
            display: flex;
            flex-direction: column;
            text-align: center;
            @include media-breakpoint-down(lg) {
                flex-direction: row;
            }
        }
    }
}

.goBack {
    @include iconBox;
    box-shadow: inset 0px 0px 1px 1px rgba($white, 0.12) !important;
    @include transition3;
    .icon {
        @include x;
        background-size: 18px;
        height: 18px;
        width: 18px;
    }
    &:hover {
        background-color: rgba($white, 0.06);
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
}
