@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

// JOIN US
.tts-join-inner {
    // position: relative;
    // padding-top: 220px;
    form {
        margin-top: 50px;
    }
    .text_placeholder {
        font-size: $size-24;
        color: var(--gray-600);
        font-weight: $medium;
        margin-bottom: 30px;
    }
    .inputs-check {
        margin-bottom: 50px;
    }
    .footer-form {
        margin-top: 50px;
        .btn-submit {
            font-weight: $medium;
            font-size: $size-15;
            color: $white;
        }
        .security_text {
            display: flex;
            align-items: center;
            gap: 12px;
            p {
                font-weight: $medium;
                font-size: $size-14;
                color: var(--gray-400);
                margin: 0;
                width: 100%;
            }
            .icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg id='Group_355' data-name='Group 355' transform='translate(-0.5 -0.5)'%3E%3Crect id='Rectangle_209' data-name='Rectangle 209' width='22' height='22' transform='translate(0.5 0.5)' fill='none'/%3E%3Crect id='Rectangle_210' data-name='Rectangle 210' width='16' height='12' rx='1' transform='translate(3.5 7.5)' fill='%23a2a3a7' opacity='0.2'/%3E%3Cpath id='Rectangle_211' data-name='Rectangle 211' d='M-6.607-8H7.32A1.394,1.394,0,0,1,8.713-6.607V3.142A1.394,1.394,0,0,1,7.32,4.535H-6.607A1.394,1.394,0,0,1-8,3.142V-6.607A1.394,1.394,0,0,1-6.607-8ZM7.32,3.142V-6.607H-6.608V3.142Z' transform='translate(10.84 15.018)' fill='%23a2a3a7'/%3E%3Cpath id='Path_1255' data-name='Path 1255' d='M90.964,15.66a.7.7,0,0,1-.7-.7V11.83a2.437,2.437,0,0,0-4.875,0v3.134a.7.7,0,0,1-1.393,0V11.83a3.83,3.83,0,0,1,7.66,0v3.134A.7.7,0,0,1,90.964,15.66Z' transform='translate(-76.634 -7.249)' fill='%23a2a3a7'/%3E%3Ccircle id='Ellipse_17' data-name='Ellipse 17' cx='1' cy='1' r='1' transform='translate(10.5 12.5)' fill='%23a2a3a7'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 22px;
                width: 22px;
                height: 22px;
                display: inline-block;
            }
        }
    }
    .tts-companyDetails {
        display: grid;
        gap: 34px;
        .name_co {
            h2 {
                font-size: $size-24;
                font-weight: $medium;
                color: var(--white);
                margin-bottom: 2px;
            }
            p {
                font-size: $size-16;
                font-weight: $medium;
                color: var(--gray-200);
                margin: 0;
            }
        }
        .sub_title {
            font-size: $size-15;
            font-weight: $medium;
            color: var(--gray-200);
            margin-bottom: 12px;
            display: block;
        }
        .desc_title {
            font-size: $size-18;
            font-weight: $medium;
            color: var(--white);
        }
        a {
            text-decoration: none;
            &.linear {
                span {
                    @include linear;
                }
            }
        }
        
    }
    .nav-tabs {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
    }
    .divider_text {
        font-size: $size-18;
        font-weight: $medium;
        margin-bottom: 20px;
        color: var(--gray-600);
    }
}

.tts-other-links {
    
    .btn {
        border-radius: $radius-14;
        padding: 20px 24px;
        background-color: rgba($white, .1);
        display: flex;
        text-align: right;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: 0 0 1px 1px transparent !important;
        @include transition3;
        .title {
            font-size: $size-18;
            font-weight: $medium;
            color: var(--white);
            margin-bottom: 8px;
        }
        .desc {
            font-size: $size-14;
            color: var(--gray-400);
            margin: 0;
        }
        .icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23fcfcfc' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cline x1='216' y1='128' x2='40' y2='128' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cpolyline points='112 56 40 128 112 200' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/polyline%3E%3C/svg%3E");
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            background-size: 18px;
            position: absolute;
            left: 24px;
            opacity: 0;
            transform: translateX(18px);
            transition: .5s cubic-bezier(0.79,0.14,0.15,0.86);
        }
        &.out_link {
            .icon {
                transform: rotate(45deg) translateX(18px);
            }
        }
        &:hover {
            background-color: transparent;
            box-shadow: 0 0 1px 1px rgba($white, .3) !important;
            .icon {
                transform: translateX(0);
                opacity: 1;
            }
            &.out_link {
                .icon {
                    transform: rotate(45deg) translateX(0);
                }
            }
        }
    }
}