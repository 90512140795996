
// MY THEME FONTS SIZE 

$size-12: 12px;
$size-13: 13px;
$size-14: 14px;
$size-15: 15px;
$size-16: 16px;
$size-17: 17px;
$size-18: 18px;
$size-19: 19px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-34: 34px;
$size-60: 60px;

// MY THEME FONTS WEIGHT 

$normal: 400;
$medium: 500;
$semiBold: 600;
$bold:   700;

// BORDER RADIUS

$radius-0:       0;
$radius-14:      14px;
$radius-20:      20px;
$radius-rounded: 100px;
$radius-circle: 50%;

//BORDER

$border:  1px;
$border-2: 2px;


// SPACE
$space-section: 150px;
$space-section-inner: 80px;

// MY THEME COLORS

$blue:   #6ba2ff;
$purple: #5f409a;
$pink:   #952c7f;
$red:    #fb3f3f;
$orange: #ea494e;
$yellow: #ea9949;
$green:  #40d8aa;

// WHITE AND GRAY COLORS

$white:    #fff;
$gray-100: #f3f3f3;
$gray-200: #d0d1d3;
$gray-300: #b9b9bc;
$gray-400: #a2a3a7;
$gray-500: #8a8b90;
$gray-600: #73757b;
$gray-700: #5c5d64;
$gray-800: #45474f;
$gray-900: #171923;
$black:    #000;

//MY THEME COLORS VARIABLES

$primary:   $orange;
$secondary: $pink;
$light:     $gray-100;
$dark:      $gray-900;

// ROOT
:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --light: #{$light};
  --dark: #{$dark};
  --white: #{$white};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --black: #{$black};
  --space-section: #{$space-section};
  --size-34: #{$size-34};
  --size-60: #{$size-60};
  --space-section-inner: #{$space-section-inner};
}

//CUSTOM GRID BREAKPOINTS => 1400 XXL 1550
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1550px
);


// CUSTOME CONTAINER
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// CUTOME OTHERS

@media (min-width: 1200px) and (max-width: 1286px) {
  .container {
      max-width: 1080px !important;
  }
}


// IMPORT BOOTSTRAP-V5
 @import "../scss/bootstrap/bootstrap.scss";