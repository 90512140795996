@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

// FOOTER

.tc-main-footer {
    padding: 80px 0;
    position: relative;
    .tc-content {
        .display-sections-title {
            .tc-email {
                display: inline-block;
                font-size: $size-20;
                font-weight: $medium;
                margin: 30px 0 0 0;
                color: var(--white);
                @include linear;
                text-decoration: none;
            }
        }
    }
    .dividar-block {
        height: 1px;
        width: 100%;
        background-color: var(--white);
        margin: 70px 0;
        display: block;
        opacity: .1;
    }
    .subscribe_whatsapp_news {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        p {
            font-size: $size-18;
            color: var(--gray-200);
            margin: 0;
            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }
        .btn-whatsapp {
            background-color: var(--white);
            color: var(--dark);
            border-radius: $radius-rounded;
            font-size: $size-15;
            font-weight: $medium;
            box-shadow: inset 0 0 1px 1px rgba($white, 0) !important;
            transition: .4s all;
            &:hover {
                background-color: transparent;
                color: var(--white);
                box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;
            }
        }
    }
    
    .tc-wrapper-address {
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
        .address {
            font-size: $size-16;
            font-weight: $normal;
            color: var(--gray-200);
            margin-bottom: 14px;
            @include media-breakpoint-up(lg) {
                width: 70%;
            }
        }
        .phone {
            text-decoration: none;
            color: var(--white);
            font-weight: $medium;
            font-size: $size-16;
        }
        .privacy_copyright {
            margin-top: 24px;
            @include media-breakpoint-down(lg) {
                margin-top: 20px;
            }
            a {
                color: var(--white);
                font-size: $size-14;
                display: inline-block;
                text-decoration: none;
                span {
                    @include linear;
                }
            }
            .dividar {
                font-size: $size-14;
                font-weight: $medium;
                display: inline-block;
                margin: 0 20px;
                opacity: .3;
            }
            .copyright {
                color: var(--gray-400);
                font-size: $size-14;
            }
        }
        .social-networks {
            margin-top: 24px;
            .nav {
                .nav-item {
                    .nav-link {
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }
    }
    .nav_links {
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
        .nav-item {
            margin-bottom: 14px;
            .nav-link {
                font-size: $size-18;
                color: var(--white);
                font-weight: $medium;
                @include data-hover;
            }
        }
    }
    .logo_bussines {
        position: relative;
        margin: auto;
        width: 140px;
        height: 140px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='152' height='152.001' viewBox='0 0 152 152.001'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.025' y1='0.639' x2='1' y2='0.409' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23fff' stop-opacity='0.4'/%3E%3Cstop offset='1' stop-color='%23fff' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath id='Subtraction_5' data-name='Subtraction 5' d='M-7457,152a75.523,75.523,0,0,1-29.583-5.973,76.009,76.009,0,0,1-12.91-7.007,76.553,76.553,0,0,1-11.248-9.281,76.543,76.543,0,0,1-9.281-11.248,76.007,76.007,0,0,1-7.007-12.91A75.516,75.516,0,0,1-7533,76a75.514,75.514,0,0,1,5.973-29.583,76,76,0,0,1,7.007-12.91,76.54,76.54,0,0,1,9.281-11.248,76.554,76.554,0,0,1,11.248-9.28,76.008,76.008,0,0,1,12.91-7.007A75.526,75.526,0,0,1-7457,0a75.521,75.521,0,0,1,29.583,5.972,76.012,76.012,0,0,1,12.91,7.007,76.547,76.547,0,0,1,11.248,9.28,76.558,76.558,0,0,1,9.28,11.248,76.007,76.007,0,0,1,7.007,12.91A75.525,75.525,0,0,1-7381,76a75.527,75.527,0,0,1-5.972,29.583,76.005,76.005,0,0,1-7.007,12.91,76.565,76.565,0,0,1-9.28,11.248,76.547,76.547,0,0,1-11.248,9.281,76.016,76.016,0,0,1-12.91,7.007A75.518,75.518,0,0,1-7457,152Zm0-150a74.084,74.084,0,0,0-74,74,74.085,74.085,0,0,0,74,74,74.084,74.084,0,0,0,74-74A74.083,74.083,0,0,0-7457,2Z' transform='translate(7533)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            background-size: 100%;
            background-repeat: no-repeat;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
