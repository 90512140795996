@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

/*
    // IMPORT 'Tajawal' FROM GOOGLE FONTS
    
    REGULAR   :   400
    MEDIUM    :   500
    SEMIBOLD  :   600
    BOLD      :   700

    // THESE ARE THE NAMES IF YOU ARE USING A DESIGN FIILE SUCH AS ADOBE XD.
*/
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
//@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;500;700&display=swap");

// HEADER TITLE
.display-head-title {
    .display-typeText {
        padding: 10px 18px;
        border-radius: $radius-rounded;
        background-color: rgba($white, 0.06);
        width: max-content;
        margin: 0 auto 20px auto;

        .text {
            font-size: $size-15;
            font-weight: $medium;
        }

        .divider {
            font-weight: $medium;
            font-size: $size-14;
            opacity: 0.3;
            margin: 0 10px;
        }

        .texTyped {
            font-weight: $normal;
            font-size: $size-15;
        }
    }

    .tag_categories {
        margin-bottom: 20px;
    }

    .display-title {
        h1 {
            font-size: var(--size-60);
            //font-weight: $bold;

            font-weight: 800;
            line-height: 76px;

            letter-spacing: -3px;

            @include media-breakpoint-down(md) {
                line-height: normal;
            }
        }

        .desc_short {
            font-size: $size-15;
            font-weight: $medium;
            color: var(--gray-200);
            margin: 20px 0 0 0;

            a {
                display: inline-block;
                text-decoration: none;
                color: var(--primary);
            }
        }
    }

    .btn-gradient {
        margin-top: 30px;
        width: 180px;
        position: relative;
        overflow: hidden;
    }
}

.display-sections-title {
    .tc_before_title {
        font-size: $size-18;
        //font-weight: $normal;
        font-weight: $medium;
        &.gradient-text {
            @include gradient-text;
        }
    }

    h2 {
        font-size: var(--size-34);
        font-weight: $bold;
        margin: 0;
        //line-height: 52px;
        color: var(--white);

        letter-spacing: -2px;
        line-height: 47px;
        @include media-breakpoint-down(lg) {
            line-height: normal;
        }
    }

    p {
        font-size: $size-18;
        font-weight: $normal;
        color: var(--gray-200);
        margin-top: 14px;
        margin-bottom: 0;
        
        letter-spacing: -1px;
    }
}

.content__text {
    .wrapper_content {
        margin-top: 40px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h5 {
            font-size: $size-24;
            color: var(--white);
            font-weight: $medium;
            margin-bottom: 34px;

            &.is-link {
                font-size: $size-20;
            }
        }
        p {
            font-size: $size-20;
            line-height: 34px;
            color: var(--gray-100);
            margin-bottom: 34px;
        }
        a {
            color: var(--primary);
            @include transition3;
        }
        .dividar {
            border-top: 1px solid var(--white);
            opacity: 0.1;
            margin: 40px 0;
        }
        ul {
            li {
                font-size: $size-18;
                line-height: 34px;
                color: var(--gray-100);
                padding: 10px 4px;
            }
        }
    }
}
