@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tts-notfound {
    position: relative;
    z-index: 2;
}

.svg_notfound {
    position: absolute;
    width: 380px;
    height: 380px;
    bottom: -6%;
    left: calc(50% - 190px);
    z-index: 0;
    pointer-events: none;
    @include media-breakpoint-down(sm) {
        width: 350px;
        height: 350px;
        left: calc(100% / 2 - 175px);
        bottom: 10px;
    }
    svg {
        path {
            fill: var(--white);
        }
    }
}
