@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


$pagination-padding-x: 10px;
$pagination-padding-y: 8px;
$pagination-font-size: $size-14;
$pagination-border-radius: 10px;
$pagination-color: var(--white);
$pagination-border-color: rgba($white, .1);
$pagination-hover-color:var(--white);
$pagination-active-color: $white;
$pagination-active-bg: var(--primary);
$pagination-active-border-color: var(--primary);
$pagination-bg: transparent;

.pagination {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 40px;
    &:not(.disabled) {
        gap: 8px;
    }
    .page-item {
        padding: 0;
        .page-link {
            font-weight: $medium;
            border-radius: 10px !important;
            height: 38px;
            min-width: 38px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            color: var(--white) !important;
            border-color: rgba($white, .1);
            padding: 8px 10px;
            @include transition2;
            &:hover {
                background-color: rgba($white, .05);
            }
        }
        &.disabled {
            margin: 0 -8px;
            background-color: transparent;
            pointer-events: none;
            .page-link {
                border: 0;
                background-color: transparent;
                color: var(--gray-600);
            }
        }
        &.active {
            .page-link {
                background-color: var(--primary) !important;
            }
        }
    }
}