@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

@keyframes bg_animation {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@keyframes slide-mix {
    0%,
    100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(-4px);
    }
}

@keyframes animerotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes wobble-hor-bottom {
    0%,
    100% {
        transform: translateX(0%);
        transform-origin: 20% 20%;
    }
    15% {
        transform: translateX(-10px) rotate(-4deg);
    }
    30% {
        transform: translateX(10px) rotate(4deg);
    }
    45% {
        transform: translateX(-10px) rotate(-3.6deg);
    }
    60% {
        transform: translateX(8px) rotate(2.4deg);
    }
    75% {
        transform: translateX(-4px) rotate(-1.2deg);
    }
}

@keyframes slide-to-divider {
    0% {
        transform: translateY(-80px);
        opacity: 0;
    }
    100% {
        opacity: 0.06;
        transform: translateY(0);
    }
}

@keyframes slide-to-heading {
    0% {
        transform: translateY(-130px);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bounce-top {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }
    24% {
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    65% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    82% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    93% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

@keyframes fade-in-filter {
    0% {
        opacity: 0;
        transform: scale3d(0.6, 0.6, 0.6);
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
@keyframes fade-out-filter {
    0% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
    100% {
        opacity: 0;
        transform: scale3d(0.6, 0.6, 0.6);
    }
}


@keyframes fade-in-2 {
    0% {
        opacity: 0;
        transform: translateY(18px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fade-out-2 {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(18px);
    }
}

@keyframes slide-in-ads {
    from {
        transform: translateX(-400px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-ads {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-400px);
    }
}


@keyframes slide-in-close-ads {
    from {
        transform: translateX(-60px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-close-ads {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-60px);
    }
}

@keyframes slide-in-toastmsg {
    from {
        transform: translateY(120px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out-toastmsg {
    from {
        transform: translateY(0);
        
    }
    to {
        transform: translateY(120px);
    }
}