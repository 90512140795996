@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tts-we-do {
    .space {
        margin-top: 40px;
    }
}

.features-inner-tc {
    padding-top: var(--space-section);
    .item {
        text-align: center;
        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }
        .icon {
            background-repeat: no-repeat;
            background-size: 38px;
            width: 38px;
            height: 38px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            &.subscribe {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23fcfcfc' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpath d='M128,32A96,96,0,0,0,63.8,199.4h0A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,64.2,39.4A96,96,0,0,0,128,32Z' opacity='0.2'%3E%3C/path%3E%3Ccircle cx='128' cy='120' r='40' fill='none' stroke='%23fcfcfc' stroke-miterlimit='10' stroke-width='16'%3E%3C/circle%3E%3Cpath d='M63.8,199.4a72,72,0,0,1,128.4,0' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3Cline x1='176' y1='56' x2='224' y2='56' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cline x1='200' y1='32' x2='200' y2='80' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cpath d='M222.8,112.9A93.3,93.3,0,0,1,224,128a96,96,0,1,1-96-96,93.3,93.3,0,0,1,15.1,1.2' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3C/svg%3E");
            }
            &.price {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23fcfcfc' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpath d='M122.7,25.9,42,42,25.9,122.7a8,8,0,0,0,2.2,7.2L132.5,234.3a7.9,7.9,0,0,0,11.3,0l90.5-90.5a7.9,7.9,0,0,0,0-11.3L129.9,28.1A8,8,0,0,0,122.7,25.9Z' opacity='0.2'%3E%3C/path%3E%3Cpath d='M122.7,25.9,42,42,25.9,122.7a8,8,0,0,0,2.2,7.2L132.5,234.3a7.9,7.9,0,0,0,11.3,0l90.5-90.5a7.9,7.9,0,0,0,0-11.3L129.9,28.1A8,8,0,0,0,122.7,25.9Z' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3Ccircle cx='84' cy='84' r='12'%3E%3C/circle%3E%3C/svg%3E");
            }
            &.star {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23fcfcfc' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpath d='M138.7,175.5l-19.2,52.1a8,8,0,0,1-15,0L85.3,175.5a8.1,8.1,0,0,0-4.8-4.8L28.4,151.5a8,8,0,0,1,0-15l52.1-19.2a8.1,8.1,0,0,0,4.8-4.8l19.2-52.1a8,8,0,0,1,15,0l19.2,52.1a8.1,8.1,0,0,0,4.8,4.8l52.1,19.2a8,8,0,0,1,0,15l-52.1,19.2A8.1,8.1,0,0,0,138.7,175.5Z' opacity='0.2'%3E%3C/path%3E%3Cpath d='M138.7,175.5l-19.2,52.1a8,8,0,0,1-15,0L85.3,175.5a8.1,8.1,0,0,0-4.8-4.8L28.4,151.5a8,8,0,0,1,0-15l52.1-19.2a8.1,8.1,0,0,0,4.8-4.8l19.2-52.1a8,8,0,0,1,15,0l19.2,52.1a8.1,8.1,0,0,0,4.8,4.8l52.1,19.2a8,8,0,0,1,0,15l-52.1,19.2A8.1,8.1,0,0,0,138.7,175.5Z' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3Cline x1='176' y1='16' x2='176' y2='64' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cline x1='200' y1='40' x2='152' y2='40' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cline x1='224' y1='72' x2='224' y2='104' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cline x1='240' y1='88' x2='208' y2='88' fill='none' stroke='%23fcfcfc' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3C/svg%3E");
            }
        }
        h3 {
            font-size: $size-22;
            font-weight: $medium;
            margin-bottom: 14px;
        }
        p {
            font-size: $size-15;
            font-weight: $normal;
            margin: 0;
            color: var(--gray-200);
        }
    }
}

.tts-title-wrapper {
    &.space-sm {
        padding-bottom: 60px;
        padding-top: 130px;
        @include media-breakpoint-down(md) {
            padding-bottom: 40px;
            padding-top: 80px;
        }
    }
}

.brands-inner-tc {
    .wrapper-brands {
        .item-logo-inside {
            height: 110px;
            img {
                filter: grayscale(100%);
                height: 55px;
                @include media-breakpoint-up(xl) {
                    min-width: 180px;
                }
            }
        }
    }
}

.products-techsoup {
    .item {
        padding: 30px 24px;
        display: flex;
        align-items: flex-start;
        gap: 20px;
        background-color: rgba($white, 0.05);
        border-radius: $radius-14;
        margin-bottom: 30px;
        min-height: 200px;
        .icon {
            width: 45px;
            height: 45px;
            border-radius: 10px;
        }
        .display-text {
            h4 {
                font-size: $size-16;
                font-weight: $bold;
                color: var(--white);
                margin-bottom: 10px;
            }
            p {
                font-size: $size-15;
                color: var(--gray-200);
                font-weight: $normal;
                margin: 0;
            }
        }
    }
}

.accordion {
    .accordion-item {
        background-color: transparent;
        box-shadow: inset 0 0 1px 1px rgba($white, 0.12) !important;
        border-radius: $radius-14;
        margin-bottom: 20px;
        border: 0;
        .accordion-header {
            .accordion-button {
                background-color: transparent;
                border: 0;
                padding: 24px;
                border-radius: $radius-14;
                color: var(--white);
                font-size: $size-18;
                font-weight: $medium;
                text-align: right;
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg id='Group_7327' data-name='Group 7327' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Rectangle_264' data-name='Rectangle 264' width='20' height='20' fill='none'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M3.376-10.037H-11.019A.981.981,0,0,1-12-11.019.981.981,0,0,1-11.019-12H3.376a.981.981,0,0,1,.981.981A.981.981,0,0,1,3.376-10.037Z' transform='translate(14.142 21.339)' fill='%23fff'/%3E%3Cpath id='Line_2' data-name='Line 2' d='M-11.019,4.357A.981.981,0,0,1-12,3.376V-11.019A.981.981,0,0,1-11.019-12a.981.981,0,0,1,.981.981V3.376A.981.981,0,0,1-11.019,4.357Z' transform='translate(21.339 14.142)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 20px;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 20px;
                    opacity: 0.7;
                }
                &[aria-expanded="true"] {
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .accordion-body {
            padding: 0 24px 24px 24px;
            p {
                font-size: $size-16;
                font-weight: $normal;
                color: var(--gray-200);
                margin: 0;
                a {
                    text-decoration: none;
                }
            }
        }
    }
}

.tc-newsletter {
    .wrapper-subscribe {
        .display-sections-title {
            p {
                color: rgba($white, 0.95);
            }
        }
        .btn-outline {
            box-shadow: inset 0 0 1px 1px $white !important;
            color: $white !important;
            &:hover {
                background-color: $white;
                color: $dark !important;
            }
        }
    }
}

.techsoup_footer {
    .about_techsoup {
        .brand_tc {
            margin-bottom: 24px;
            display: block;
            text-decoration: none;
            img {
                width: 139px;
                height: 40px;
                object-fit: contain;
            }
            .light {
                display: none;
            }
        }
        p {
            font-size: $size-18;
            color: var(--gray-200);
        }
        .copyright {
            color: var(--gray-400);
            font-size: $size-14;
            margin-top: 24px;
        }
    }
}

.header_techsoup {
    .navbar-brand {
        &.techsoup {
            img {
                width: 139px;
                height: 40px;
                object-fit: contain;
            }
        }
    }
    .navbar-nav {
        display: none !important;
    }
    .partner_tts {
        display: flex;
        align-items: center;
        gap: 24px;
        p {
            font-size: $size-13;
            color: var(--gray-300);
            position: relative;
            margin: 0;
            &::before {
                content: "/";
                font-size: $size-18;
                color: var(--white);
                opacity: 0.14;
                position: absolute;
                right: -24px;
                top: 6px;
            }
        }
    }
}
