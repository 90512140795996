@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.btn-sm {
    position: relative;
    padding: 10px 18px;
}
.btn-md {
    position: relative;
    padding: 13px 23px;

    letter-spacing: -1px;
}

.btn-lg {
    position: relative;
    padding: 16px 26px;

    letter-spacing: -1px;
}

.btn-gradient {
    display: inline-flex;
    align-items: center;
    border-radius: $radius-rounded;
    column-gap: 6px;
    border: 0;

    letter-spacing: -1px;
    span {
        font-size: $size-14;
        font-weight: $semiBold;
        color: $white;
        display: inline-block;
    }
    .icon_arrow {
        background-size: 21px;
        width: 21px;
        height: 21px;
        position: absolute;
        left: 20px;
        @include arrow-circle-left;
        @include transition2;
    }
    .icon_service {
        background-size: 21px;
        width: 21px;
        height: 21px;
        position: absolute;
        left: 0;
        @include chat-teardrop-text;
        @include transition2;
        opacity: 0;
    }
    @include gradient_anime;
    background-size: 400%;
    animation: bg_animation 10s infinite alternate;
    &:hover {
        .icon_arrow {
            opacity: 0;
            left: 10px;
        }
        .icon_service {
            opacity: 1;
            left: 20px;
        }
    }
}

.btn-outline {
    font-size: $size-15;
    font-weight: $medium;
    color: var(--white) !important;
    border-radius: $radius-rounded;
    box-shadow: inset 0 0 1px 1px var(--white) !important;
    @include transition3;

    letter-spacing: -1px;
    &:hover {
        background-color: var(--white);
        color: var(--dark) !important;
    }
}

.view-more-button {
    font-size: $size-14;
    font-weight: $medium;
    color: var(--gray-200) !important;
    border-radius: $radius-rounded;
    box-shadow: inset 0 0 1px 1px var(--gray-600) !important;
}

.gradient-outline {
    @include gradient-outline;
    color: var(--white) !important;
}

.btn-download {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: $radius-rounded;
    color: $white !important;
    gap: 6px;

    letter-spacing: -1px;
    .icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_607' data-name='Group 607' transform='translate(0.122 0.122)'%3E%3Crect id='Rectangle_253' data-name='Rectangle 253' width='18' height='18' transform='translate(-0.122 -0.122)' fill='none'/%3E%3Cpath id='Path_1293' data-name='Path 1293' d='M20.334,53.417a5.417,5.417,0,1,1,5.417,5.417H19.792a3.792,3.792,0,1,1,0-7.584,3.894,3.894,0,0,1,.941.115' transform='translate(-14.931 -44.765)' fill='%23fff' opacity='0.2'/%3E%3Cpath id='Path_1294' data-name='Path 1294' d='M112.937,169.479a.54.54,0,0,1-.383-.159l-2.3-2.3a.542.542,0,1,1,.766-.766l1.913,1.913,1.913-1.913a.542.542,0,1,1,.766.766l-2.3,2.3A.54.54,0,0,1,112.937,169.479Z' transform='translate(-102.659 -154.867)' fill='%23fff'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M-7.458-1.5A.542.542,0,0,1-8-2.041V-7.458A.542.542,0,0,1-7.458-8a.542.542,0,0,1,.542.542v5.417A.542.542,0,0,1-7.458-1.5Z' transform='translate(17.736 16.111)' fill='%23fff'/%3E%3Cpath id='Path_1295' data-name='Path 1295' d='M13.959,96.668H12.334a4.334,4.334,0,1,1,0-8.668,4.439,4.439,0,0,1,1.07.131.542.542,0,0,1-.262,1.051,3.37,3.37,0,0,0-.81-.1,3.25,3.25,0,1,0,0,6.5h1.625a.542.542,0,1,1,0,1.083Z' transform='translate(-7.473 -82.056)' fill='%23fff'/%3E%3Cpath id='Path_1296' data-name='Path 1296' d='M82.293,49.711a.542.542,0,0,1-.433-.867,4.876,4.876,0,1,0-8.776-2.925.542.542,0,1,1-1.083,0,5.959,5.959,0,1,1,10.727,3.576A.541.541,0,0,1,82.293,49.711Z' transform='translate(-67.139 -37.266)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 18px;
        width: 18px;
        height: 18px;
    }
}
