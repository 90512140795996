@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.divider_text_forms {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--gray-600);
}
.form-floating,
.input-group {
    position: relative;
    margin-bottom: 20px;
    > {
        label {
            left: inherit;
            right: 0;
            font-size: $size-18;
            color: var(--gray-600);
            font-weight: $medium;
            padding: 24px;
            transition: 0.5s transform cubic-bezier(0.86, 0, 0.07, 1) !important;
            transform-origin: right;
            opacity: 1 !important;
        }
    }
    .form-control {
        height: 80px !important;
        border-radius: $radius-14;
        box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;
        border: 0;
        background-color: rgba($white, 0);
        color: var(--white) !important;
        font-size: $size-18;
        font-weight: $medium;
        padding-left: 24px !important;
        padding-right: 24px !important;
        @include transition3;
        &:not(:placeholder-shown) {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.12) !important;
            background-color: rgba($white, 0.02);
            ~ {
                label {
                    transform: scale(0.7) translateY(-25px) translateX(-8px) !important;
                }
            }
        }
        &::placeholder {
            font-size: $size-18;
            color: var(--gray-600);
            font-weight: $medium;
            opacity: 0;
        }
        &:hover {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
        }
        &:focus {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
            background-color: rgba($white, 0.02);
            ~ {
                label {
                    transform: scale(0.7) translateY(-25px) translateX(-8px) !important;
                }
                .icon {
                    transform: translateX(0);
                    opacity: 0.8;
                }
            }
        }
        &.is-invalid {
            background-image: none;
            box-shadow: inset 0 0 1px 1px $yellow !important;
        }
    }
    .invalid-feedback {
        color: $yellow;
        font-size: $size-14;
        margin-top: 12px;
    }
    .icon {
        background-size: 24px;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 24px;
        top: 30px;
        opacity: 0;
        transform: translate(-14px);
        transition: 0.4s cubic-bezier(0.86, 0, 0.07, 1);
        &.icon-user {
            @include user;
        }
        &.icon-mail {
            @include envelope-open;
        }
        &.icon-phone {
            @include device-mobile-camera;
        }
        &.icon-build {
            @include buildings;
        }
        &.icon-file {
            @include file-text;
        }
        &.icon-briefcase {
            @include briefcase;
        }
    }
    textarea {
        min-height: 160px;
        resize: none;
        &.form-control {
            padding-top: 48px !important;
        }
    }
}

.input-group {
    &.text-group {
        height: 80px;
        background-color: transparent;
        box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;
        border-radius: $radius-14;
        position: relative;
        overflow: hidden;
        .input-title-text {
            background-color: transparent;
            font-size: $size-18;
            color: var(--gray-600);
            font-weight: $medium;
            border: 0;
            padding: 10px 24px;
            display: inline-flex;
            align-items: center;
        }
        .input-group-text {
            padding: 0;
            background-color: transparent;
            border: 0;
            margin-right: auto;
            .item {
                width: 150px;
                border-right: 2px solid rgba($white, 0.12);
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                @include media-breakpoint-down(sm) {
                    width: 80px;
                }
                .btn-check {
                    &:checked {
                        + {
                            label {
                                background-color: rgba($white, 0.03);
                                &::before {
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    border-bottom: 10px solid var(--white);
                                }
                                span {
                                    color: var(--white);
                                }
                                .icon-male,
                                .icon-female {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                label {
                    display: flex;
                    align-items: center;
                    column-gap: 14px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    @include transition3;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 0 solid transparent;
                        border-right: 0 solid transparent;
                        border-bottom: 0 solid var(--white);
                        top: -2px;
                        right: -9px;
                        transform: rotate(45deg);
                        @include transition3;
                    }
                }
                span {
                    color: var(--gray-600);
                    font-size: $size-18;
                    font-weight: $medium;
                    @include transition3;
                }
                .icon-male {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Ccircle cx='104' cy='152' r='72' opacity='0.2'%3E%3C/circle%3E%3Ccircle cx='104' cy='152' r='72' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/circle%3E%3Cline x1='154.9' y1='101.1' x2='216' y2='40' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cpolyline points='168 40 216 40 216 88' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/polyline%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: 24px;
                    width: 24px;
                    height: 24px;
                    opacity: 0.7;
                    @include transition3;
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
                .icon-female {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Ccircle cx='128' cy='96' r='72' opacity='0.2'%3E%3C/circle%3E%3Ccircle cx='128' cy='96' r='72' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/circle%3E%3Cline x1='128' y1='168' x2='128' y2='240' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Cline x1='88' y1='208' x2='168' y2='208' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: 24px;
                    width: 24px;
                    height: 24px;
                    opacity: 0.7;
                    @include transition3;
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
    }
}

// INPUT CHECK
.inputs-check {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .item {
        .btn-check {
            &:checked {
                + {
                    .btn {
                        background-color: var(--white);
                        color: var(--dark);
                    }
                }
            }
        }
        .btn {
            font-size: $size-15;
            font-weight: $medium;
            color: var(--white);
            border-radius: $radius-rounded;
            box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;

            letter-spacing: -.8px;
            @include transition3;
            @include media-breakpoint-down(sm) {
                font-size: $size-13;
                padding: 12px 18px;
            }
        }
    }
}

// UPLOAD FILE
.input-upload {
    margin-bottom: 20px;
    label {
        font-size: $size-18;
        color: var(--gray-600);
        font-weight: $medium;
        margin-bottom: 12px;
    }
    .feedback-text {
        font-size: $size-14;
        color: var(--gray-300);
        margin-top: 12px;
    }
}

// FILEPOND
.filepond--root {
    .filepond--credits {
        display: none;
    }
    .filepond--drop-label {
        color: var(--gray-600);
        .filepond--label-action {
            color: var(--white);
            text-decoration: none;
        }
    }
    .filepond--panel-root {
        border-radius: $radius-14;
        background-color: rgba($white, 0.06);
    }
    .filepond--panel-top {
        &::after {
            opacity: 0;
        }
    }
    .filepond--panel-bottom {
        &::before {
            opacity: 0;
        }
    }
    .filepond--item-panel {
        background-color: rgba($white, 0.2);
        border-radius: $radius-14;
    }
    .filepond--drip {
        border-radius: $radius-14;
    }
    [data-filepond-item-state*="error"],
    [data-filepond-item-state*="invalid"] {
        .filepond--item-panel {
            background-color: $yellow;
        }
    }
    [data-filepond-item-state="processing-complete"] {
        .filepond--item-panel {
            background-color: $green;
        }
    }
    .filepond--file-action-button {
        &:hover,
        &:focus {
            box-shadow: 0 0 0 0.125em var(--white) !important;
        }
    }
}

// SEARCH
.input-group-search {
    position: relative;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
    }
    .input-group-text {
        position: absolute;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 0;
        padding: 10px 20px;
        @include media-breakpoint-down(md) {
            position: relative;
            margin-bottom: 18px;
            padding: 0;
            &::after {
                display: none;
            }
        }
        &::after {
            content: "";
            width: 2px;
            height: 60%;
            background-color: var(--white);
            opacity: 0.12;
            position: absolute;
            left: 0;
        }
        .btn {
            border-radius: $radius-rounded;
            background-color: transparent;
            font-size: $size-15;
            font-weight: $medium;
            color: var(--gray-400);
            &[aria-current="page"] {
                background-color: var(--white);
                color: var(--dark);
            }
        }
    }
    .form-control {
        padding-right: 45% !important;
        height: 80px !important;
        border-radius: $radius-14;
        box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;
        border: 0;
        background-color: rgba($white, 0);
        color: var(--white) !important;
        font-size: $size-18;
        font-weight: $medium;
        padding-left: 24px !important;
        @include transition3;
        @include media-breakpoint-down(lg) {
            padding-right: 42% !important;
        }
        @include media-breakpoint-down(md) {
            padding-right: 24px !important;
        }
        &::placeholder {
            color: var(--gray-600) !important;
            font-size: $size-15 !important;
            font-weight: $normal !important;
        }
        &::-webkit-search-cancel-button {
            display: none;
        }
        &:hover {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
        }
        &:focus {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
            background-color: rgba($white, 0.02);
        }
    }
    .btn-search {
        position: absolute;
        height: 50px;
        width: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        left: 10px;
        z-index: 2;
        @include media-breakpoint-down(md) {
            top: 85px;
        }
        &::before {
            content: "";
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg id='Group_562' data-name='Group 562' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_252' data-name='Rectangle 252' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_41' data-name='Ellipse 41' cx='8' cy='8' r='8' transform='translate(3 3)' fill='%23f3f3f3' opacity='0.2'/%3E%3Cpath id='Ellipse_42' data-name='Ellipse 42' d='M.812-8A8.822,8.822,0,0,1,9.624.812,8.822,8.822,0,0,1,.812,9.624,8.822,8.822,0,0,1-8,.812,8.822,8.822,0,0,1,.812-8Zm0,16.091A7.288,7.288,0,0,0,8.091.812,7.288,7.288,0,0,0,.812-6.468,7.288,7.288,0,0,0-6.468.812,7.288,7.288,0,0,0,.812,8.091Z' transform='translate(10.299 10.299)' fill='%23f3f3f3'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M-2.579-1.813a.764.764,0,0,1-.542-.224L-7.776-6.692a.766.766,0,0,1,0-1.084.766.766,0,0,1,1.084,0l4.655,4.655a.766.766,0,0,1,0,1.084A.764.764,0,0,1-2.579-1.813Z' transform='translate(24.034 24.034)' fill='%23f3f3f3'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            background-size: 24px;
            transition: 0.4s all;
        }
        &:hover {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg id='Group_562' data-name='Group 562' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_252' data-name='Rectangle 252' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_41' data-name='Ellipse 41' cx='8' cy='8' r='8' transform='translate(3 3)' fill='%23ea494e' opacity='0.2'/%3E%3Cpath id='Ellipse_42' data-name='Ellipse 42' d='M.812-8A8.822,8.822,0,0,1,9.624.812,8.822,8.822,0,0,1,.812,9.624,8.822,8.822,0,0,1-8,.812,8.822,8.822,0,0,1,.812-8Zm0,16.091A7.288,7.288,0,0,0,8.091.812,7.288,7.288,0,0,0,.812-6.468,7.288,7.288,0,0,0-6.468.812,7.288,7.288,0,0,0,.812,8.091Z' transform='translate(10.299 10.299)' fill='%23ea494e'/%3E%3Cpath id='Line_1' data-name='Line 1' d='M-2.579-1.813a.764.764,0,0,1-.542-.224L-7.776-6.692a.766.766,0,0,1,0-1.084.766.766,0,0,1,1.084,0l4.655,4.655a.766.766,0,0,1,0,1.084A.764.764,0,0,1-2.579-1.813Z' transform='translate(24.034 24.034)' fill='%23ea494e'/%3E%3C/svg%3E%0A") !important;
            }
        }
    }
}

.form-title-simple {
    h4 {
        font-size: $size-18;
        color: var(--gray-600);
        font-weight: $medium;
        margin-bottom: 24px;
    }
}

.form-check {
    margin-bottom: 20px;
    label {
        font-size: $size-18;
        color: var(--gray-200);
        font-weight: $medium;
        margin-bottom: 0;
        cursor: pointer;
        @include transition2;
    }
    .form-check-input {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background: transparent;
        border: 2px solid rgba($white, 0.3);
        background-size: 20px;
        background-size: 18px;
        box-shadow: none;
        background-repeat: no-repeat;
        background-position: 0px;
        cursor: pointer;
        @include transition2;
        &:checked[type="checkbox"] {
            background-color: var(--primary);
            border-color: var(--primary);
            ~ {
                label {
                    color: var(--white);
                }
            }
        }
        &[type="radio"] {
            border-radius: $radius-circle;
            background-size: 21px;
            &:checked {
                background-color: var(--primary);
                border-color: var(--primary);
                ~ {
                    label {
                        color: var(--white);
                    }
                }
            }
        }
    }
}


// SELECT 
.custom-select {
    display: flex;
    .nice-select {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: right !important;
        height: 80px !important;
        border-radius: $radius-14;
        box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;
        border: 0;
        background-color: rgba($white, 0);
        color: var(--white) !important;
        font-size: $size-18;
        font-weight: $medium;
        padding-left: 24px !important;
        padding-right: 24px !important;
        @include transition3;
        &:not(:placeholder-shown) {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.12) !important;
            background-color: rgba($white, 0.02);
            ~ {
                label {
                    transform: scale(0.7) translateY(-24px) translateX(-8px) !important;
                }
            }
        }
        &::placeholder {
            font-size: $size-18;
            color: var(--gray-600);
            font-weight: $medium;
            opacity: 0;
        }
        &:hover {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
        }
        &:focus {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
            background-color: rgba($white, 0.02);
            ~ {
                .icon {
                    transform: translateX(0);
                    opacity: 0.8;
                }
            }
        }
        &.is-invalid {
            background-image: none;
            box-shadow: inset 0 0 1px 1px $yellow !important;
        }
        &:after {
            left: 24px;
            width: 6px;
            height: 6px;
            right: inherit;
        }
        .multiple-options {
            margin-top: 10px;
            .current {
                margin: 0;
            }
        }
        .current {
            margin-top: 10px;
        }
        .nice-select-dropdown {
            width: 100%;
            border-radius: $radius-14;
            background-color: $white;
            box-shadow: 0px 19px 60px -24px rgba($dark, .2);
            z-index: 9;
            .list {
                padding: 14px 0;
                margin: 0;
                li {
                    color: $dark;
                    border-radius: 0;
                    text-align: right;
                    font-size: $size-16;
                    font-weight: $medium;
                    margin-top: -1px;
                    @include transition2;
                    &:hover {
                        background-color: $light;
                    }
                    &.selected {
                        color: var(--gray-600);
                        background-color: $light !important;
                    }
                }
            }
        }
    }
}