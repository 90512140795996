@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

// TESTMONIALS SECTION
.tc-compines-success {
    position: relative;
    padding: 100px 0;
    background-color: rgba($white, 0.01);
    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }
    &::before {
        content: "";
        background: linear-gradient(to left, rgba($white, 0), rgba($white, 0.03) 10%, rgba($white, 0));
        height: 2px;
        position: absolute;
        top: 0;
        width: 100%;
        background-size: 400%;
        animation: bg_animation 4s infinite alternate;
    }
    &::after {
        content: "";
        background: linear-gradient(to right, rgba($white, 0), rgba($white, 0.03) 10%, rgba($white, 0));
        height: 2px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-size: 400%;
        animation: bg_animation 4s infinite alternate;
    }
    .componies-swiper {
        padding: 60px 0 0 0;
        pointer-events: none;
        position: relative;
        .item_img {
            img {
                filter: invert(0.5);
                mix-blend-mode: luminosity;
                width: 100%;
                height: 50px;
                object-fit: scale-down;
                
            }
        }
    }
}
